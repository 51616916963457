import {
  type AccessDatum,
  type PrevMonthAccessDatum,
  type PrevYearMonthAccessDatum,
} from 'types/access_data';
import { getRequest } from 'utils/rest-api-util';
import { StringUtil } from 'utils/string-util';

export type AccessDataGraphType = AccessDatum &
  PrevMonthAccessDatum &
  PrevYearMonthAccessDatum;
export const graphLabelObject: Record<string, string> = {
  pageViews: 'ページビュー',
  sessions: 'セッション',
  engagedSessions: 'エンゲージセッション',
  users: 'ユーザー',
  newUsers: '新規ユーザー',
};
export const graphMetricsArray = Object.keys(graphLabelObject);
export type GraphMetrics = (typeof graphMetricsArray)[number];
export type ProgressData = {
  pageViews: {
    currentMonth: number;
    prevMonth: number;
    prevYearMonth: number;
    monthOnMonth: number;
    yearOnYear: number;
  };
  sessions: {
    currentMonth: number;
    prevMonth: number;
    prevYearMonth: number;
    monthOnMonth: number;
    yearOnYear: number;
  };
  users: {
    currentMonth: number;
    prevMonth: number;
    prevYearMonth: number;
    monthOnMonth: number;
    yearOnYear: number;
  };
};

export const getAccessData = async (
  analyticId: string,
  searchParams?: URLSearchParams,
): Promise<AccessDataGraphType[]> => {
  let requestPath = `/api/v1/analytics/${analyticId}/access_data`;
  if (searchParams) {
    requestPath += `?${searchParams.toString()}`;
  }

  const response = await getRequest(requestPath);

  return StringUtil.snakeObjectToCamelObject(
    await response.json(),
  ) as AccessDataGraphType[];
};
